.ant-layout {
    background: transparent !important;
}
.container {
    width: 1345px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}
ul {
    margin: 0;
    padding: 0;
}
li {
    list-style-type: none;
}
.columns {
    display: flex;
    flex-wrap: wrap;
}
.w-4 {
    width: calc(4 / 12 * 100%);
}
.w-3 {
    width: calc(3 / 12 * 100%);
}
.w-8 {
    width: calc(8 / 12 * 100%);
}
.w-6 {
    width: calc(6 / 12 * 100%);
}
.mt-30{
    margin-top: 20px;
}
.App {
    background: #151515;
    font-family: 'Archivo', sans-serif;
    color: #fff;
    overflow: hidden;
    // height: 100vh;
    // overflow-y: auto;
}
.header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    
    z-index: 999;
}
.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 22px 0;
    border-bottom: 1px solid rgba(241, 241, 241, 0.75);
    position: relative;
    z-index: 99;
    &.res {
        border-bottom: 0px solid rgba(241, 241, 241, 0.3);
    }
    .left {
        .box-img {
            img {
                width: 188px;
                height: 43px;
            }
        }
    }
    .right {
        display: flex;
        align-items: center;
    }
}
.main-menu {
    margin-right: 30px;
    .list-menu {
        display: flex;
        align-items: center;
        li {
            a {
                color: #F1F1F1BF;
                font-family: Archivo;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 22.5px; /* 150% */
                text-transform: uppercase;
                display: flex;
                align-items: center;
                padding: 10px 15px;
                transition: 0.3s ease;
                position: relative;
                z-index: 1;
                &:before {
                    content: "";
                    width: 80%;
                    height: 1px;
                    background: #F1F1F1;
                    position: absolute;
                    bottom: 5px;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: 0.3s ease;
                    z-index: -1;
                }
                &:hover {
                    color: #36D191;
                }
                &:hover:before {
                    background: #36D191;
                }
                img {
                    width: 18px;
                    margin-left: 15px;
                }
            }
        }
    }
}
.mt-15{
    margin-top: 15px;
}
.butotn-row {
    .btn {
        text-align: center;
        font-family: Archivo;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        padding: 8px 20px;
        transition: 0.3s ease;
        border: 1px solid transparent;
        cursor: pointer;
        &.btn-touch {
            background: #36D191;
            color: #151515;
            a {
                color: #151515 !important;
            }
            &:hover{
                color: #fff !important;
            }
            
        }
        &.btn-banner {
            background: #36D191;
            color: #151515;
            display: flex;
            align-items: center;
            border: 1px solid transparent;
            a {
                color: #151515 !important;
                display: flex;
                align-items: center;

            }
            &:hover {
                background: #36D191;
                color: #fff !important;
                border: 1px solid #fff;
            }
            img {
                width: 18px;
                margin-left: 10px;
            }
        }
        &:hover {
            border: 1px solid #36D191;
            background: #36D191;
            color: #36D191;
        }
    }
}
.wrap-banner {
    padding: 85px 0 125px 0;
    position: relative;
    background: #151515;

    .banner-bottom {
        position: absolute;
        bottom: 70px;
        right: 15%;
        transform: translateX(-50%);
        animation: crescendo 1.5s alternate infinite ease-in;
        img {
            width: 300px;
        }
    }
    .banner-right {
        position: absolute;
        right: 0;
        bottom: 0;
        animation: crescendorow 1.5s alternate infinite ease-in;
        img {
            width: 600px;
        }
    }
    .banner-top {
        position: absolute;
        right: 17%;
        bottom: 55%;
        animation: crescendorow 1.5s alternate infinite ease-in;
        img {
            width: 70px;
        }
    }
    .main-banner {
        .title-wrap {
            color: #151515;
            text-align: center;
            font-family: Archivo;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px; /* 162.5% */
            text-transform: uppercase;
            background: #36D191;
            padding: 3px 10px;
            width: fit-content;
            margin-bottom: 15px;
        }
        .title {
            color: #FFFFFF;
            font-family: Archivo;
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.3;
            margin-bottom: 15px;
            .main-color {
                color: #151515;
                font-family: Archivo;
                font-size: 70px;
                font-style: normal;
                font-weight: 700;
                line-height: 96px;
                background: #36D191;
            }
            
        }
        .desc {
            color: #FFFFFF;
            font-family: Archivo;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 1.4;
            margin-bottom: 40px;
        }
    }
}
.bg-green{
    background: rgba(54, 209, 145, 1);
}
.bg-white {
    background: #fff !important;
}
// .wrap-making {
//     background: #fff;
//     position: relative;

    
//     .main-making {
//         padding: 65px 0 100px 0;
        
//         .title-mk{
//             max-width: 50%;
//             color: rgba(20, 44, 40, 1);
//             font-size: 50px;
//             font-weight: 700;
//             line-height: 52px;
//             letter-spacing: 0em;
//             text-align: left;
//         }
//         .desc-mk{
//             max-width: 50%;
//             margin-top: 20px;
//             font-size: 20px;
//             font-weight: 300;
//             line-height: 26px;
//             letter-spacing: 0em;
//             text-align: left;
//             color: rgba(20, 44, 40, 1);
//         }
        
//     }
//     .row-making{
//         padding-bottom: 100px;
//         margin-top: -50px;
//         position: relative;
//         z-index: 99;

//         .mk-item{
//             border-bottom: 3px solid rgba(54, 209, 145, 1);
//             background: linear-gradient(0deg, #142C28, #142C28), linear-gradient(0deg, #36D191, #36D191);
//             min-height: 330px;
//             padding: 20px;
//             margin-bottom: 15px;

//             .mk-number{
//                 color: rgba(54, 209, 145, 1);
//                 font-size: 60px;
//                 font-weight: 500;
//                 line-height: 65px;
//                 letter-spacing: 0em;
//                 text-align: left;
//                 margin-bottom: 50px;
//             }
//             .mk-title{
//                 color: rgba(255, 255, 255, 1);
//                 font-size: 30px;
//                 font-weight: 400;
//                 line-height: 26px;
//                 letter-spacing: 0em;
//                 text-align: left;
//                 margin-bottom: 20px;
//             }
//             .mk-description{
//                 font-size: 18px;
//                 font-weight: 300;
//                 line-height: 26px;
//                 letter-spacing: 0em;
//                 text-align: left;
//             }
//         }
//     }
//     .making-banner{
//         position: absolute;
//         left: 0;
//         bottom: 0;
//         img{
//             width: 100%;
//             max-width: 400px;
//         }
//     }
// }

.wrap-making {
    padding: 65px 0 65px 0;
    background: #FFF;
    position: relative;

    .banner-making {
        position: absolute;
        bottom: 0;
        left: 0;

        img {
            width: 425px;
        }
    }

    .banner-making-top {
        position: absolute;
        animation: crescendorow 1.5s alternate infinite ease-in;
        bottom: 30%;
        left: 15%;

        img {
            width: 160px;
        }
    }

    .main-making {
        color: #142C28;

        .title-small {
            color: #142C28;
            font-family: Archivo;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.4;
            /* 150% */
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        .row-content-pres {
            .columns {
                align-items: center;
            }
        }

        .row-content {
            .guide-colum {
                .title-mk {
                    color: #142C28;
                    font-family: Archivo;
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.4;
                    margin-bottom: 20px;
                }

                .desc-mk {
                    color: #142C28;
                    font-family: Archivo;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 1.4;
                }

                .number {
                    color: #36D191;
                    font-family: Archivo;
                    font-size: 100px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: block;
                    line-height: 1.5;
                }

                &.row {
                    padding-left: 40px;
                }

                &.res {
                    padding-top: 110px;
                }

                .list-row {
                    li {
                        padding-bottom: 50px;

                        &:last-child {
                            padding-bottom: 0;
                        }

                        .txt-top {
                            color: #142C28;
                            font-family: Archivo;
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px;
                            margin-bottom: 10px;
                        }

                        .txt-bottom {
                            color: #142C28;
                            font-family: Archivo;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
}

.wrap-partner {
    padding: 70px 0 85px 0;
    position: relative;
    .banner-partner {
        position: absolute;
        right: 0;
        top: 0;
        img {
            width: 300px;
        }
    }
    .img-partner {
        position: absolute;
        right: 10%;
        top: 20%;
        img {
            width: 215px;
        }
    }
    .main-partner {
        .title-small {
            color: #4FD69E;
            font-family: Archivo;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px; /* 150% */
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        .title {
            color: #FFF;
            font-family: Archivo;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 52px; 
            margin-bottom: 10px;
            padding-right: 300px;
        }
        .desc {
            color: #F1F1F1;
            font-family: Archivo;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px; 
            margin-bottom: 30px;
            padding-right: 300px;
        }
        .list-partner {
            margin-top: 70px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li {
                width: 20%;
                padding: 15px 0;
                a {
                    transition: 0.3s ease;
                    border: 1px solid transparent;
                    display: block;
                    text-align: center;
                    &:hover {
                        border-right: 1px solid #36D191;
                        border-bottom: 1px solid #36D191;
                        background: #36d19121;
                    }
                    img {
                        width: 181px;
                    }
                }
            }
        }
    }
}
.text-center{
    text-align: center;
}
.wrap-project {
    padding: 75px 0 60px 0;
    background: #36D191;
    .main-project {
        .title-small {
            color: #151515;
            font-family: Archivo;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px; /* 150% */
            text-transform: uppercase;
            margin-bottom: 17px;
        }
        .title {
            color: #151515;
            font-family: Archivo;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 52px;
            margin-bottom: 50px;
        }
        .row-project {
            margin-bottom: 40px;
            .columns {
                justify-content: space-between;
            }
            .w-3 {
                width: 24%;
            }
            .content-row {
                background: #142C28;
                padding: 25px 25px 25px 25px;
                position: relative;
                height: 428px;
                // border: 2px solid transparent;
                transition: 0.3s ease;
                cursor: pointer;
                overflow: hidden;
                transform: scale(1);
                &:hover {
                    // border: 2px solid #fff;
                    transform: scale(1.03);
                }
                .card-banner {
                    position: absolute;
                    right: 0;
                    top: 0;
                    img {
                        width: 200px;
                        transform: scale(1);
                        transition: 0.3s ease;
                    }
                }
                &:hover .card-banner img {
                    transform: scale(1.13);
                }
                .number {
                    color: #36D191;
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 135.5%; /* 21.68px */
                    letter-spacing: 0.16px;
                    position: absolute;
                    left: 25px;
                    top: 25px;
                }
                .txt-top {
                    color: #FFF;
                    font-family: Archivo;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 34.816px; 
                    margin-bottom: 5px;
                    transition: 0.3s ease;
                }
                .txt-bottom {
                    color: rgba(255, 255, 255, 0.70);
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 1.4; /* 21.68px */
                    letter-spacing: 0.16px; 
                }
                &:hover .txt-top {
                    color: #36D191;
                }
                .icon {
                    margin-bottom: 10px;
                    padding-top: 80%;
                    img {
                        width: 24px;
                    }
                }
            }
        }
        .butotn-row {
            .btn-banner {
                margin: 0 auto;
            }
        }
    }
}
.wrap-maturity {
    padding: 60px 0 65px 0;
    background: #142C28;
    .main-maturity {
        .item {
            display: flex;
            align-items: center;
            padding: 28px;
            border-top: 1px solid #0F4A32;
            transition: 0.3s ease;
            cursor: pointer;
            &:hover .title {
                color: #fff;
                text-shadow: 1px 0px 3px #fff;
            }
            &:last-child {
                border-bottom: 1px solid #0F4A32;
            }
            .number {
                color: #36D191;
                font-family: Archivo;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 135.5%; /* 21.68px */
                letter-spacing: 0.16px;
                margin-right: 30px;
            }
            .title {
                color: #B8C0BE;
                font-family: Archivo;
                font-size: 70px;
                font-style: normal;
                font-weight: 400;
                line-height: 45.316px; /* 64.737% */
                text-transform: uppercase;
                margin-right: 40px;
                transition: 0.3s ease;
            }
            .list-matu {
                li {
                    list-style-type: disc;
                    color: #36D191;
                    font-family: Archivo;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: 135.5%; /* 24.39px */
                    letter-spacing: 0.18px;
                }
            }
        }
    }
}
.algorithm-footer-allright{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    span {
        color: rgba(255, 255, 255, 0.70);
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.14px;
        margin-right: 20px;
    }

    .ft-txt {
        color: rgba(255, 255, 255, 1);
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.14px;
    }
}
.footer {
    padding: 50px 0 30px 0;
    background: #404D4C;
    &.res {
        background: #142C28;
    }
    .algorithm-footer{
        width: 100%;
        display: block !important;

        .left{
            width: 100% !important;
            text-align: center;

            .box-img {
                margin-bottom: 25px;
        
                img {
                    width: 183px;
                }
            }
        
            .txt {
                color: #FFF;
                font-family: Archivo;
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.3px;
                margin-bottom: 28px;
        
                span {
                    color: #36D191;
                    font-family: Archivo;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.3px;
                }
            }
            .algorithm-footer-menu {
                width: 100%;
                text-align: center;
                margin-bottom: 20px;

                a {
                    color: #fff;
                    padding-left: 15px;
                    padding-right: 15px;
                }

            }
            .list-social {
                display: flex;
                align-items: center;

                span {
                    display: contents;
                }

                span:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }

                .btn-ft {
                    border-radius: 1000px;
                    border: 0px solid transparent;
                    background: #FFF;
                    padding: 10px 20px;
                    color: #142C28;
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.16px;
                    position: relative;
                    margin-right: 20px;

                    a {
                        color: #142C28;
                    }

                    &::before {
                        content: '';
                        width: 1px;
                        height: 40px;
                        background: #fff;
                        position: absolute;
                        right: -20px;
                        top: 0;
                    }
                }

                img {
                    cursor: pointer;
                    width: 20px;
                    margin-left: 20px;
                    margin-right: 5px;
                }
            }
            .list-social{
                display: block !important;

                span{
                    display: inline-flex !important;
                    line-height: 20px;
                }
            }
        }
    }
    .main-footer {
        display: flex;
        flex-wrap: wrap;
        .left {
            width: 50%;
            .box-img {
                margin-bottom: 25px;
                img {
                    width: 183px;
                }
            }
            .txt {
                color: #FFF;
                font-family: Archivo;
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.3px;
                margin-bottom: 28px;
                span {
                    color: #36D191;
                    font-family: Archivo;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.3px;
                }
            }
            
            .list-social {
                display: flex;
                align-items: center;

                span{
                    display: contents;
                }
                span:hover{
                    cursor: pointer;
                    text-decoration: underline;
                }
                .btn-ft {
                    border-radius: 1000px;
                    border: 0px solid transparent;
                    background: #FFF;
                    padding: 10px 20px;
                    color: #142C28;
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.16px;
                    position: relative;
                    margin-right: 20px;
                    a {
                        color: #142C28;
                    }
                    &::before {
                        content: '';
                        width: 1px;
                        height: 40px;
                        background: #fff;
                        position: absolute;
                        right: -20px;
                        top: 0;
                    }
                }
                img {
                    cursor: pointer;
                    width: 20px;
                    margin-left: 20px;
                    margin-right: 5px;
                }
            }
            .list-privaci {
                margin-top: 20px;
                span {
                    color: rgba(255, 255, 255, 0.70);
                    font-family: Archivo;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: 0.14px;
                    margin-right: 20px;
                }
                .ft-txt {
                    color: rgba(255, 255, 255, 1);
                    font-family: Archivo;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: 0.14px;
                }
            }
        }
        .right {
            width: 50%;
            .row-menu-ft {
                display: flex;
                justify-content: space-between;
                .item {
                    padding-left: 50px;
                    .title {
                        color: rgba(255, 255, 255, 0.70);
                        font-family: Archivo;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.16px;
                        margin-bottom: 25px;
                    }
                    a {
                        color: #FFF;
                        font-family: Archivo;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.16px;
                        text-decoration: none;
                        padding-bottom: 20px;
                        display: block;
                        transition: 0.3s ease;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
@keyframes crescendo {
    0%   {transform: scale(1);}
    100% {transform: scale(1.2);}
}
@keyframes crescendorow {
    0%   {transform: scale(1);}
    100% {transform: scale(1.1);}
}
.number-more {
    color: #99D2B4;
    text-align: center;
    font-family: Archivo;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 135.5%; /* 24.39px */
    letter-spacing: 0.18px;
    margin-top: 15px;
}
.wrap-email {
    position: relative;
    padding: 121px 0 15% 0;
    height: 100vh;
    .banner-email-1 {
        position: absolute;
        right: 10%;
        bottom: 5%;
        img {
            width: 310px;
        }
    }
    .banner-email-2 {
        position: absolute;
        right: 0;
        bottom: 0;
        img {
            width: 360px;
        }
    }
    .content-email {
        .title {
            color: #FFF;
            font-family: Archivo;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 52px;
            margin-bottom: 10px;
        }
        .desc {
            color: #36D191;
            font-family: Archivo;
            font-size: 130px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 3cqmin;
            cursor: pointer;
        }
        .desc-email {
            color: #F1F1F1;
            font-family: Archivo;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px; 
            margin-bottom: 20px;
        }
        .input-email {
            display: flex;
            align-items: center;
            input {
                background: #181C1B;
                color: rgba(241, 241, 241, 0.40);
                font-family: Archivo;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 26px;
                padding: 20px;
                width: 480px;
                border: 0px solid transparent;
            }
            &:focus {
                outline: none;
            }
            button {
                width: 185px;
                color: #151515;
                font-family: Archivo;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 5px;
                height: 66px;
                cursor: pointer;
                img {
                    width: 18px;
                    margin-left: 10px;
                }
            }
        }
        .email-contact {
            color: #FFF;
            font-family: Archivo;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 52px;
            margin-top: 90px;
            span {
                display: block;
                color: #36D191;
                font-family: Archivo;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 135.5%; /* 27.1px */
                letter-spacing: 0.2px;
                margin-top: 10px;
                display: flex;
                align-items: center;
                img {
                    width: 24px;
                    margin-left: 5px;
                }
            }
        }
    }
}
:focus {
    outline: none;
}
.d-desktop{
    display: flex;
}
.d-mobile {
    display: none !important;
}
.hide-menu {
    display: none;
}

.show-mobile {
    display: block;
}
@media screen and (max-width: 768px) {
    .wrap-making .main-making .row-content .guide-colum .number{
        font-size: 60px;
    }
    .algorithm-footer-allright span{
        display: flow;
        margin-top: 10px;
    }
    .footer .algorithm-footer .left .list-social{
        text-align: center;
        margin: auto;
    }
    .footer .algorithm-footer .left .list-social span{
        margin-top: 15px;
    }
    .footer .algorithm-footer .left .list-social .btn-ft::before {
        display: none;
    }
    .d-desktop {
        display: none;
    }
    .d-mobile {
        display: block !important;
    }
    .wrap-banner {
        padding: 55px 0 100px 0;
        position: relative;
    }
    .wrap-banner .banner-right{
        opacity: 0.4;
    }
    .wrap-banner .main-banner .title{
        font-size: 30px;
        line-height: 65px;
    }
    .wrap-banner .main-banner .title .main-color{
        font-size: 30px;
        line-height: 65px;
    }
    .row-content .columns .w-4{
        width: 100%;
    }
    .row-content .columns .w-8 {
        width: 100%;
    }
    .row-content-pres .columns .w-6 {
        width: 100%;
    }
    .wrap-making .main-making .title-mk{
        font-size: 40px;
        max-width: 100%;
    }
    .wrap-making .main-making .desc-mk {
        max-width: 100%;
    }
    .wrap-making .row-making{
        margin-top: 20px;
    }
    .guide-colum ul.list-row{
        margin-top: 30px;
    }
    .wrap-partner .main-partner .title{
        padding-right: 0px;
        font-weight: 400;
        font-size: 30px;
        position: relative;
        margin-bottom: 40px;
    }
    .wrap-partner .img-partner{
        opacity: 0.6;
    }
    .wrap-partner .main-partner .desc{
        padding-right: 0px;
        line-height: 35px;
    }
    .wrap-partner .main-partner .list-partner li {
        width: 33%;
    }
    .wrap-partner .main-partner .list-partner li a img {
        width: 100%;
    }
    .wrap-project .main-project .row-project .w-3 {
        width: 100%;
        margin-bottom: 15px;
    }
    .wrap-project .main-project .row-project .content-row{
        height: auto;
    }
    .wrap-project .main-project .row-project .content-row .icon {
        padding-top: 50%;
    }
    .wrap-maturity .main-maturity .item{
        display: block;
        padding: 15px;
    }
    .wrap-maturity .main-maturity .item .title{
        font-size: 35px;
    }
    .wrap-maturity .main-maturity .item .list-matu li{
        line-height: 30px;
    }
    .footer .main-footer .left{
        width: 100%;
    }
    .footer .main-footer .right {
        width: 100%;
    }
    .footer .main-footer .left .box-img{
        margin-bottom: 0px;
    }
    .footer .main-footer .right .row-menu-ft{
        display: block;
    }
    .footer .main-footer .right .list-privaci span{
        margin-right: 20px;
        line-height: 30px;
    }
    .footer .main-footer .right .row-menu-ft .item{
        padding-left: 0px;
        margin-top: 30px;
        width: 100%;
    }
    .menu-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1005;
    }

    .menu-mobile .menu-mobile-ul {
        padding-top: 15px;
    }
    .menu-mobile .ant-menu-root {
        height: 100vh;
        // background: linear-gradient(247.4deg, #0E67FF 0%, #1E2430 100%) !important;
        background: rgba(0, 0, 0, 0.9);
        margin-top: 0px;
    }
    .item-menu-clo, .item-menu-clo:hover, .item-menu-clo:focus, .item-menu-clo:active{
        background-color: transparent !important;
    }
    .item-menu-clo .ant-menu-title-content{
        text-align: right;
    }
    .btn-menu-mobile{
        background-color: transparent;
        border: 1px solid #fff;
    }
    .btn-menu-mobile svg{
        font-size: 20px;
    }
    .btn-menu-mobile-close{
        background: transparent;
    }
    .btn-menu-mobile-close svg{
        font-size: 30px;
    }
    :where(.css-dev-only-do-not-override-nnuwmp).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
        color: #fff;
        background-color: transparent;
    }
    .wrap-email .content-email .title{
        font-size: 25px;
    }
    .wrap-email .content-email .desc{
        font-size: 45px;
    }
    .wrap-email .content-email .email-contact{
        margin-top: 50px;
        font-size: 25px;
    }
    .wrap-making .banner-making img{
        width: 700px;
        opacity: 0.6;
    }
    .wrap-making .main-making .row-content .guide-colum .list-row li .txt-top{
        margin-top: 7px;
        margin-bottom: 15px;
        font-size: 35px;
    }
    .wrap-making .main-making .row-content .guide-colum.res{
        padding-top: 0px;
    }
    .wrap-making .main-making {
        position: relative;
        z-index: 999;
    }
    .wrap-email .content-email .desc-email{
        line-height: 30px;
    }
    .content-email{
        z-index: 999;
        position: relative;
    }
    .wrap-banner .banner-bottom{
        display: none;
    }
    .wrap-banner .banner-top {
        right: 5%;
        bottom: 7%;
    }
    .wrap-making .banner-making-top{
        display: none;
    }
    .wrap-making .main-making .row-content .guide-colum .list-row li .txt-bottom {
        line-height: 30px;
        margin-top: 10px;
    }
    .a-menu-mobile{
        padding-bottom: 7px;
        border-bottom: 0.5px solid #fff;
    }
    .a-menu-mobile:hover {
        padding-bottom: 7px;
        border-bottom: 0.5px solid #fff;
    }
    .menu-mobile-ul .ant-menu-item-selected{
        background-color: transparent !important;
    }
    .menu-mobile-ul .ant-menu-item-selected .a-menu-mobile{
        color: #36D191;
        border-bottom: 0.5px solid #36D191;
    }
    .footer .main-footer .left .list-social{
        display: block;
    }
    .footer .main-footer .left .list-social .btn-ft{
        display: block;
        margin-bottom: 10px;
    }
    .footer .main-footer .left .list-social span {
        display: flex;
            width: 100%;
                margin-bottom: 7px;
    }
    .footer .main-footer .left .list-social .btn-ft::before{
        display: none;
    }
    .footer .main-footer .left .list-social img:nth-child(1){
        margin-left: 0px;
    }
}