.block-overlay-star {
    background: url("../../public/images/bg-main.png") center center no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
}
.block-overlay-green {
    background: linear-gradient(90.18deg, #122724 0.17%, #091311 99.85%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.3
}
.p-main{
padding: 40px 80px;
}
.main-page{
    // background:linear-gradient(90.18deg, #122724 0.17%, #091311 99.85%), url("../../public/images/bg-main.png");
    // background-position: center center;
    // background-size: cover;
    height: 100vh;
    background: linear-gradient(90.18deg, #040908 0.17%, #091311 99.85%);
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    overflow-y: auto;

    .home-header{
        height: auto;
        display: flex;
        position: relative;
        z-index: 999;

        .header-logo{
            margin-top: 30px;
            margin-bottom: 30px;
            width: 100%;
            img{
                width: auto;
            }
        }
        .header-copyright{
            width: 50%;
            text-align: right;
            span{
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }
    .home-spield-logo{
        margin-top: 3rem;
        margin-bottom: 2rem;
        width: 100%;
        position: relative;
        z-index: 9999;

        img{
            width: auto;
        }
    }
    
    .home-content{
        max-width: 65%;
        position: relative;
        z-index: 999;
        .title{
            
            
            margin-bottom: 3.2rem;
            margin-top: 3rem;

            background: linear-gradient(90deg, #5cc9b5 0, #fff 10%, #61d6a5 20%);
            background-position: 0;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-animation: shineText 5s linear infinite;
            animation: shineText 5s linear infinite;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            -webkit-text-size-adjust: none;
            text-decoration: none;
            white-space: nowrap;
            width: 100%;
            color: #fff;
            font-size: 60px;
            letter-spacing: 0em;
            text-align: left;
            font-weight: 600;
        }
        .description{
            // font-family: Aeonik;
            font-size: 20px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0.01em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
        .block-list{
            margin-top: 3rem;

            .block-item{
                width: 100%;
                border-radius: 20px;
                text-align: center;
                border-top: 4px solid rgba(222, 255, 241, 1);
                margin-top: 20px;
                padding: 30px 15px;

                .item-logo{
                    margin-bottom: 15px;
                }
                .item-title{
                    margin: auto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 26px;
                    color: rgba(41, 36, 19, 1);
                    border: 1px solid rgba(41, 36, 19, 1);
                    padding: 5px 10px;
                    width: fit-content;
                    border-radius: 15px;
                    text-transform: uppercase;
                }
                .item-description{
                    margin-top: 20px;
                    color: rgba(41, 36, 19, 1);
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: 0em;
                }
                .item-viewmore{
                    margin: auto;
                    margin-top: 20px;
                    background: rgba(20, 44, 40, 1);
                    border-radius: 15px;
                    padding: 10px 15px;
                    width: fit-content;


                    .text-green{
                        color: rgba(39, 183, 123, 1);
                    }
                    .text-yellow {
                        color: #CF9538;
                    }
                    a:hover{
                        color: #fff !important;
                    }
                }
            }
            .block-item:hover{
                transform: scale(1.01);
                box-shadow: 2px 2px 2px #fff;
                cursor: pointer;
            }
            .green{
                background: linear-gradient(113.45deg, #27B77B 1%, #1C9D5F 100%), linear-gradient(0deg, #DEFFF1, #DEFFF1);
            }
            .yellow {
                background: linear-gradient(89deg, #defff12b, #defff100), linear-gradient(115.03deg, #F7DD7A 1.59%, #CF9538 97.21%);
            }
        }
    }
    .block-overlay{
        position: absolute;
        z-index: 1;
        text-align: right;
        vertical-align: middle;
        top: 0%;
        right: 0;
        width: 100%;
        height: 100%;

        video{
            width: 100%;
            height: 100%;
            max-width: 48% !important;
            max-height: 95%;
        }
        background: linear-gradient(90.18deg, #040908 0.17%, #091311 99.85%);
    }
    .block-overlay::after {
        content: '';
        background: url('../../public/images/overlay-video.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0%;
        right: 0;
    }
    
}
.main-page::before{
    content: '';
    background: url('../../public/images/overlay-left.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: block;
    position: absolute;
}
.p-footer{
    position: relative;
    width: 100%;
    text-align: right;
    z-index: 9999;
    margin-top: -15px;

    ul{
        padding-left: 0px !important;
        display: block;

        li{
            margin-right: 10px;
            display: -webkit-inline-box;

            a{
                color: #fff !important;
            }
            a:hover{
                text-decoration: underline;
            }
        }
    }
}
.wrap-cases-participated{
    padding: 70px 0 85px 0;
    position: relative;
    background: rgba(240, 249, 245, 1);

    .participated-title{
        font-size: 50px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(20, 44, 40, 1);
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .participated-block{
        margin-top: 30px;

    }
    .participated-item {
        margin-bottom: 45px;
        margin-top: 45px;
        height: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &.res {
            img {
                width: 85px;
                border-radius: 99px;
            }
        }
        img {
            max-width: 145px;
            width: auto;
        }
    }
    .participated-block-mobile{
        display: none;
    }
    
}
.footer-copyright{
    display: none;
}
.wrap-section {
    padding-top: 91px;

    .wrap-banner-focus {
        padding: 60px 0;
        text-align: center;

        .content-asset {
            .title-sm-bn {
                color: #151515;
                background: #36D191;
                padding: 5px 10px;
                text-align: center;
                font-family: Archivo;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 26px;
                /* 162.5% */
                text-transform: uppercase;
                width: fit-content;
                margin: 0 auto;
                margin-bottom: 20px;
            }

            .title-bn {
                background: var(--dasasdasd, linear-gradient(90deg, #BFFFF4 38.95%, #00FFD1 52.33%, #00FF75 89.31%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: Archivo;
                font-size: 70px;
                font-style: normal;
                font-weight: 700;
                line-height: 1.1;
                margin-bottom: 20px;
            }

            .desc {
                color: #FFF;

                text-align: center;
                font-family: Archivo;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 26px;
                padding: 0 20%;
            }

            .img {
                img {
                    width: 80%;
                }
            }

            .list-partner-focus {
                display: flex;
                align-items: center;

                .item {
                    cursor: pointer;

                    img {
                        width: 180px;
                    }
                }
            }
        }
    }
}

.wrap-section {
    position: relative;

    .bg-div {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.wrap-investment {
    text-align: center;
    padding: 110px 0;
    background: #142C28;
    position: relative;

    .img-left-in {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .img-right-in {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .row-title {
        text-align: center;
        font-family: Archivo;
        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: linear-gradient(270deg, #A3CBC1 0.06%, #F3FCFA 97.41%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 20px;
    }

    .row-desc {
        color: #36D191;
        text-align: center;
        font-family: Archivo;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 130% */
        margin-bottom: 20px;
        padding: 0 20%;
    }

    .butotn-row {
        text-align: center;

        .btn-banner {
            margin: 0 auto !important;
        }
    }
}

.wrap-core {
    padding: 80px 0 140px 0;

    .columns {
        align-items: center;
    }

    .row-core {
        .content-core {
            &.res {
                padding-right: 50px;
            }

            .title {
                color: #36D191;
                text-align: left;
                font-family: Archivo;
                font-size: 70px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 60px;
            }

            .desc-sm {
                color: #FFF;
                margin-bottom: 20px;
                font-family: Archivo;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .desc-sl {
                color: #36D191;

                font-family: Archivo;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.2;
            }

            .box-img {
                img {
                    width: 100%;
                }
            }

            .icon {
                margin-top: 20px;
                text-align: center;

                img {
                    width: 360px;
                    margin: 0 auto;
                }
            }
        }
    }
}

.img-prev {
    position: absolute;
    left: 0;
    bottom: -50px;
    cursor: pointer;
}

.img-next {
    position: absolute;
    left: 50px;
    bottom: -50px;
    cursor: pointer;
}

.slick-dots {
    text-align: left;
    bottom: -90px;
}

.slick-dots li button:before {
    background: #163B33;
    opacity: 1;
    color: transparent;
    width: 16px;
    height: 16px;
}

.slick-dots li.slick-active button:before {
    background: #00FFD1;
    color: transparent;
    width: 16px;
    height: 16px;
}

.slick-dots li {
    margin: 0;
    margin-right: 10px;
    width: 16px;
    height: 16px;
}

.slick-dots li button {
    width: 16px;
    height: 16px;
}

.wrap-basic {
    padding: 60px 0;
    background: #fff;

    .content-basic {
        .title {
            color: #36D191;

            text-align: center;
            font-family: Archivo;
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 15px;
        }

        .list-content {
            display: flex;
            align-items: center;
            margin: 0 -10px;

            .item {
                width: 50%;
                padding: 10px;

                .row {
                    background: #36D191;
                    padding: 15px;
                    color: #FFF;
                    height: 200px;
                    font-family: Archivo;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.2;
                }
            }
        }

        .img-square {
            margin-top: 10px;

            img {
                width: 100%;
            }
        }
    }
}

.wrap-risk {
    padding: 100px 0;
    position: relative;

    .bg-risk {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .content-risk {
        .title {
            color: #EAFCF1;
            margin-bottom: 60px;
            text-align: center;
            font-family: Archivo;
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .row-risk {
            .row-net {
                width: 50%;
                border-top: 7px solid #36D191;
                padding: 30px;
                background: rgba(20, 44, 40, 0.80);

                backdrop-filter: blur(5px);

                &.res {
                    margin-left: auto;
                    margin-top: 20px;
                }

                .desc {
                    color: #FFF;
                    font-family: Archivo;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 124.8%;
                }
            }
        }
    }
}

.wrap-our-team {
    padding: 60px 0;

    .content-our-team {
        .columns {
            margin: 0 -10px;
        }
        .colum {
            padding: 10px;
        }
        .title {
            color: #36D191;
            text-align: center;
            font-family: Archivo;
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 50px;
        }

        .item {
            background: #142C28;
            padding: 15px;
            cursor: pointer;
            border: 2px solid transparent;
            transition: 0.3s ease;
            &:hover img {
                transform: scale(1.2);
            }
            &:hover {
                border: 2px solid #36D191;
            }
            .box-img {
                overflow: hidden;
                img {
                    width: 100%;
                    transition: 0.3s ease;
                    display: block;
                }
                margin-bottom: 10px;
            }
            .name {
                color: #00FFD1;

                text-align: center;
                font-family: Archivo;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 1.3;
                margin-bottom: 5px;
            }
            .txt {
                color: #FFF;

                text-align: center;
                font-family: Archivo;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 34.816px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .participated-block{
        display: none;
    }
    .participated-block-mobile {
        display: block !important;
    }
    .p-main{
        padding: 15px;
    }
    .block-overlay{
        display: none;
    }
    .header-copyright{
        display: none;
    }
    .main-page .home-header{
        display: block;
    }
    .main-page .home-header .header-logo{
        
        width: 100%;
        text-align: center;
    }
    .main-page .home-spield-logo{
        margin-top: 1.5rem;
        text-align: center;
    }
    .main-page .home-content{
        max-width: 100% !important;
    }
    .main-page .home-content .title{
        // font-size: 26px;
        // line-height: 30px;
        // text-align: center;

        margin-bottom: 2rem;
        margin-top: 2rem;
    
        background: linear-gradient(90deg, #5cc9b5 0, #fff 10%, #64e5af 20%);
        background-position: 0;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-animation: shineTextMobile 5s linear infinite;
        animation: shineTextMobile 5s linear infinite;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-text-size-adjust: none;
        text-decoration: none;
        white-space: nowrap;
        width: 100%;
        color: #fff;
        font-size: 26px;
        letter-spacing: 0em;
        text-align: center;
        line-height: normal;
    }
    .main-page .home-content .description{
        font-size: 16px;
        text-align: center;
    }
    .main-page .home-content .block-list .block-item{
        padding: 15px;
        text-align: center;
    }
    .main-page .home-content .block-list .block-item .item-title{
        font-size: 12px;
    }
    .main-page .home-content .block-list .block-item .item-description{
        font-size: 21px;
    }
    .p-footer{
        display: none;
    }
    .footer-copyright{
        display: block;
        text-align: center;
        height: 50px;
        margin-top: 15px;
        position: relative;
        span{
            color: #fff;
        }
    }
    .wrap-section .wrap-banner-focus {
        padding: 30px 0;
    }
    .wrap-section .wrap-banner-focus .content-asset .title-sm-bn {
        font-size: 15px;
    }
    .wrap-section .wrap-banner-focus .content-asset .title-bn {
        font-size: 38px;
    }
    .wrap-section .wrap-banner-focus .content-asset .desc {
        font-size: 15px;
        padding: 0;
    }
    .wrap-investment .row-title {
        font-size: 38px;
    }
    .wrap-investment {
        padding: 40px 0;
    }
    .wrap-investment .row-desc {
        padding: 0;
    }
    .wrap-investment .img-left-in {
        opacity: 0.2;
    }
    .wrap-investment .img-right-in {
        opacity: 0.2;
    }
    .w-6 {
        width: 100% !important;
    }
    .wrap-core .row-core .content-core .title {
        font-size: 38px;
        margin-bottom: 20px;
    }
    .wrap-core .row-core .content-core .desc-sm {
        font-size: 24px;
    }
    .wrap-core .row-core .content-core .desc-sl {
        font-size: 16px;
    }
    .wrap-core .row-core .content-core .icon img {
        width: 100%;
    }
    .wrap-basic .content-basic .title {
        font-size: 38px;
    }
    .wrap-basic .content-basic .list-content .item {
        width: 100% !important;
    }
    .wrap-basic .content-basic .list-content {
        flex-wrap: wrap;
    }
    .wrap-basic .content-basic .list-content .item .row {
        height: auto;
        font-size: 16px;
    }
    .wrap-risk .content-risk .title {
        font-size: 38px;
        margin-bottom: 20px;
    }
    .wrap-risk .content-risk .row-risk .row-net {
        width: 100%;
    }
    .wrap-risk .content-risk .row-risk .row-net .desc {
        font-size: 16px;
    }
    .wrap-risk {
        padding: 40px 0;
    }
    .w-3 {
        width: 100% !important;
    }
}
@keyframes shineText {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 768px;
    }

    100% {
        background-position: 1550px;
    }
}
@keyframes shineTextMobile {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 360px;
    }
}